import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage,deleteToken } from 'firebase/messaging';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyB8bWqhsjChIeA7MPOBjZKMjxcZY2__DK8",
    authDomain: "recess-fe49c.firebaseapp.com",
    projectId: "recess-fe49c",
    storageBucket: "recess-fe49c.appspot.com",
    messagingSenderId: "768259904297",
    appId: "1:768259904297:web:04c7ff1e17bcf073ce5d38"
  };

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();

//   export const getFirebaseToken = (setTokenFound) => {
//     return getToken(messaging, { vapidKey: "BGhR0BF4cSDJreRIMceK3eEMPkzR9Kimw7RnU_btMg09XZaYGQYcM6ASxPQyleyI3oTZA-ZaiD8zEqkLfksnIeQ" })
//       .then((currentToken) => {
//         if (currentToken) {
//           console.log('Current Token: ', currentToken);
//           setTokenFound(currentToken);
//         } else {
//           console.log('No registration token available.');
//           setTokenFound(false);
//         }
//       })
//       .catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//         setTokenFound(false);
//       });
//   };

export const getFirebaseToken = (setTokenFound) => {
    // Check if there's already a token in sessionStorage
    // const existingToken = sessionStorage.getItem("firebaseToken");
    // if (existingToken) {
    //   console.log("Using existing token:", existingToken);
    //   setTokenFound(existingToken);
    //   return; // Stop here if a token already exists
    // }
    // Request permission to show notifications
    Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          // User has granted permission, now retrieve the token
          return getToken(messaging, { vapidKey: "BGhR0BF4cSDJreRIMceK3eEMPkzR9Kimw7RnU_btMg09XZaYGQYcM6ASxPQyleyI3oTZA-ZaiD8zEqkLfksnIeQ" });
        } else {
          console.log('Permission denied for notifications.');
          setTokenFound(false);
          return null; // No token generated due to permission denial
        }
      })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Current Token:',currentToken);
          setTokenFound(currentToken);
          // sessionStorage.setItem("firebaseToken", currentToken);
        } else {
          console.log('No registration token available.');
          setTokenFound(false);
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        setTokenFound(false);
      });
  };

  export const deleteFirebaseToken = async (setTokenFound) => {
    try {
      const tokenDeleted = await deleteToken(messaging);
      if (tokenDeleted) {
        console.log("FCM token deleted successfully.");
        setTokenFound(false);
      } else {
        console.log("FCM token deletion unsuccessful.");
      }
    } catch (error) {
      console.error("Error deleting FCM token:", error);
      setTokenFound(false);
    }
  };


  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

    export { auth, googleProvider };